import { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Modal, Table } from "react-bootstrap";
import { UserContext } from "../../Context/UserContext";
import { Link } from "react-router-dom";
import { AccountContext } from "../../Context/AccountContext/AccountContext";
import DetalhesOurives from "./DetalhesOurives";
import { formatDate } from "@fullcalendar/core/index.js";
import { AlertaBalanco } from "../AlertaBalancoOurives/Alerta";
import { Search } from "lucide-react";
import { OrderContext } from "../../Context/OrderContext";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const ListaOurives = ({ setStatusToFetch, returnStatusFetch, visualizacao }) => {
	const [selectedId, setSelectedId] = useState(null);
	const { listUser } = useContext(UserContext);
	const { list, accountList } = useContext(AccountContext);
	const [users, setUsers] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);

	const [filteredData, setFilteredData] = useState(null);
	const { getOurivesByOrder } = useContext(OrderContext);
	const handleGetOurivesByOrder = async e => {
		e.preventDefault();
		if (filteredData.length < 9) {
			toast.dismiss();
			toast.error("Quantidade de caracteres insuficientes.", {
				position: toast.POSITION.BOTTOM_CENTER,
			});
			return;
		}
		toast.loading("Processando...", {
			position: toast.POSITION.BOTTOM_CENTER,
		});

		const payload = {
			order_code: filteredData,
		};

		const response = await getOurivesByOrder(payload);
		if (response !== false) {
			toast.dismiss();
			setSelectedId(response);
			setOverLayDisplay("flex");
		} else {
			toast.dismiss();
			toast.error("Nenhum resultado encontrado", {
				position: toast.POSITION.BOTTOM_CENTER,
			});
		}
	};
	const handleOpenModal = usuario => {
		setSelectedUser(usuario);
		setModalShow(true);
	};

	const closeModal = () => {
		setModalShow(false);
	};

	const handleListUser = async () => {
		const payload = {
			permissionType: [14, 15],
		};
		const response = await listUser(payload);
		setUsers(response.data.users);
		returnStatusFetch(false);
	};

	useEffect(() => {
		handleListUser();
		accountList([14, 15]);
	}, []);

	useEffect(() => {
		handleListUser();
		accountList([14, 15]);
	}, [setStatusToFetch]);

	const colorSaldoTotal = saldo => {
		if (saldo > 0) {
			return "text-success";
		} else if (saldo < 0) {
			return "text-danger";
		} else {
			return "text-secondary";
		}
	};

	// const detailVariant = {
	//   hidden: { opacity: 0, x: "-100%" },
	//   visible: { opacity: 1, x: "0%" },
	// };

	const [overLayDisplay, setOverLayDisplay] = useState("none");

	const OverLay = ({ children }) => {
		return (
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					display: overLayDisplay,
					justifyContent: "center",
					alignItems: "center",
					zIndex: "9999",
					backgroundColor: "rgba(0, 0, 0, 0.5)",
				}}
			>
				{children}
			</div>
		);
	};

	return (
		<>
			{/* {list.map((item) => (
        <motion.div
          layoutId={item.user_name}
          onClick={() => setSelectedId(item.user_name)}
        >
          <motion.h5>{item.user_name}</motion.h5>
        </motion.div>
      ))} */}
			<AnimatePresence>
				<OverLay>
					{selectedId &&
						list
							.filter(item => item.id_user == selectedId.ID_USER && item.permission_type == selectedId.PERMISSION_CODE)
							.map(filteredItem => (
								<motion.div exit="hidden" layoutId={`${selectedId.ID_USER}_${selectedId.PERMISSION_CODE}`}>
									<Card className="detail-content" style={{ width: "780px" }}>
										<Card.Body>
											<Card className="mb-2">
												<Card.Body>
													<motion.h5>
														<strong>Ourives responsável: </strong>
														{selectedId.USERNAME}
													</motion.h5>
													<motion.h6>
														<strong>Código do pedido:</strong>
														{selectedId.CODE}
													</motion.h6>
													<span>{selectedId.NAME_CLIENT}</span>
													<div>
														<strong>Código da Peça: </strong>
														<span>{selectedId.PART_CODE}</span>
													</div>
												</Card.Body>
											</Card>
											<Card className="d-flex justify-content-center align-items-center">
												<img src={selectedId.FILE} style={{ maxWidth: "300px" }} />
											</Card>
										</Card.Body>
										<Button
											as={motion.button}
											onClick={() => {
												setSelectedId(null);
												setOverLayDisplay("none");
											}}
										>
											Fechar
										</Button>
									</Card>
								</motion.div>
							))}
				</OverLay>
			</AnimatePresence>

			<AlertaBalanco list={list} />
			<div className="mb-3 border form-search ">
				<Form className="d-flex w-100" onSubmit={handleGetOurivesByOrder}>
					<Form.Control className="w-100" placeholder="Pesquisar" onChange={e => setFilteredData(e.target.value)} id="pesquisar" name="pesquisar"></Form.Control>
					<Button type="submit" variant="outline">
						<Search />
					</Button>
				</Form>
			</div>
			<div className="d-flex flex-column gap-3">
				{list && list.some(u => u.permission_type === 14) ? (
					<Card className="card-one">
						<Card.Body>
							<Card.Title>Ourives internos</Card.Title>

							<Table bordered hover className="w-100">
								<thead>
									<tr className="">
										<th className="">Ourives</th>
										<th className="text-center">Saldo Atual</th>
										<th className="text-center">Quebra Atual</th>
										<th className="text-center">Débitos</th>
										<th className="text-center">Cŕeditos</th>
										<th className="text-center">Último Balanço</th>
										<th className="text-center">Detalhes</th>
									</tr>
								</thead>
								<tbody>
									<AnimatePresence>
										{list &&
											list.map((u, i) => {
												if (u.permission_type === 14) {
													return (
														<motion.tr
															key={`${u.id_user}_${u.permission_type}`}
															layoutId={`${u.id_user}_${u.permission_type}`}
															className="p-2 border rounded "
														>
															<td className="" width={450}>
																{u.user_name}
															</td>
															<td
																className={`text-center ${colorSaldoTotal(
																	(u.account_debit_credit?.account_debit ? -u.account_debit_credit?.account_debit : 0) -
																		(u.account_debit_credit?.account_credit ? -u.account_debit_credit?.account_credit : 0) +
																		(u.total_break ? u.total_break : 0)
																)}`}
																width={150}
															>
																{visualizacao
																	? (
																			(u.account_debit_credit?.account_debit ? -u.account_debit_credit?.account_debit : 0) -
																			(u.account_debit_credit?.account_credit ? -u.account_debit_credit?.account_credit : 0) +
																			+(u.total_break ? u.total_break : 0)
																	  ).toFixed(3)
																	: "-"}
															</td>
															<td className="text-center" width={150}>
																{visualizacao ? (u.total_break ? u.total_break.toFixed(3) : "0.000") : "-"}
															</td>
															<td className="text-center" width={150}>
																{visualizacao
																	? u.account_debit_credit?.account_debit
																		? (-u.account_debit_credit?.account_debit).toFixed(3)
																		: "0.000"
																	: "-"}
															</td>
															<td className="text-center" width={150}>
																{visualizacao
																	? u.account_debit_credit?.account_credit
																		? u.account_debit_credit?.account_credit.toFixed(3)
																		: "0.000"
																	: "-"}
															</td>
															<td className="text-center" width={150}>
																{visualizacao
																	? u.account_balance?.created_at
																		? formatDate(u.account_balance?.created_at)
																		: "Sem balanço registrado"
																	: "-"}
															</td>
															<td className="text-center" width={150}>
																<Button onClick={() => handleOpenModal(u)} variant="primary">
																	<i class="ri-article-line"></i> Detalhes
																</Button>
															</td>
														</motion.tr>
													);
												}
											})}
									</AnimatePresence>
								</tbody>
							</Table>
						</Card.Body>
					</Card>
				) : null}

				{list && list.some(u => u.permission_type === 15) ? (
					<Card className="card-one">
						<Card.Body>
							<Card.Title>Ourives externos</Card.Title>
							<Table bordered hover className="w-100">
								<thead>
									<tr>
										<th>Ourives</th>
										<th>Saldo Atual</th>
										<th>Quebra Atual</th>
										<th>Débitos</th>
										<th>Cŕeditos</th>
										<th>Último Balanço</th>
										<th>Detalhes</th>
									</tr>
								</thead>
								<tbody>
									<AnimatePresence>
										{list &&
											list.map((u, i) => {
												if (u.permission_type === 15) {
													return (
														<motion.tr
															id={u.id_user}
															key={`${u.id_user}_${u.permission_type}`}
															layoutId={`${u.id_user}_${u.permission_type}`}
															className="p-2 border rounded "
														>
															<td className="" width={450}>
																{u.user_name}
															</td>
															<td className="text-center" width={150}>
																{visualizacao
																	? (
																			(u.account_debit_credit?.account_debit ? -u.account_debit_credit?.account_debit : 0) -
																			(u.account_debit_credit?.account_credit ? -u.account_debit_credit?.account_credit : 0)
																	  ).toFixed(3)
																	: "-"}
															</td>
															<td className="text-center" width={150}>
																{visualizacao ? (u.total_break ? (-u.total_break).toFixed(3) : "0.000") : "-"}
															</td>
															<td className="text-center" width={150}>
																{visualizacao
																	? u.account_debit_credit?.account_debit
																		? (-u.account_debit_credit?.account_debit).toFixed(3)
																		: "0.000"
																	: "-"}
															</td>
															<td className="text-center" width={150}>
																{visualizacao
																	? u.account_debit_credit?.account_credit
																		? u.account_debit_credit?.account_credit.toFixed(3)
																		: "0.000"
																	: "-"}
															</td>
															<td className="text-center" width={150}>
																{visualizacao
																	? u.account_balance?.created_at
																		? formatDate(u.account_balance?.created_at)
																		: "Sem balanço registrado"
																	: "-"}
															</td>
															<td className="text-center" width={150}>
																<Button onClick={() => handleOpenModal(u)} variant="primary">
																	<i class="ri-article-line"></i> Detalhes
																</Button>
															</td>
														</motion.tr>
													);
												}
											})}
									</AnimatePresence>
								</tbody>
							</Table>
						</Card.Body>
					</Card>
				) : null}
			</div>
			<Modal fullscreen show={modalShow} onHide={closeModal}>
				<Modal.Header closeButton>
					<Modal.Title>Detalhes de Operações </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<DetalhesOurives selectedUser={selectedUser} />
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ListaOurives;
