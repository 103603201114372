import { GiMeltingMetal } from "react-icons/gi";
import { MdOutlineDraw, MdPersonOutline } from "react-icons/md";
import { TbHammer } from "react-icons/tb";
import { AiFillGold } from "react-icons/ai";
import { FaGem } from "react-icons/fa";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import { MdVerified } from "react-icons/md";
import { FaBoxOpen, FaDolly, FaExternalLinkAlt } from 'react-icons/fa';
import { FaCodePullRequest } from "react-icons/fa6";
import { PiWarningCircle } from "react-icons/pi";

const utp = localStorage.getItem("utp");

const dashboardMenu = [
  {
    id: "3",
    label: "Pedidos",
    link: "/pedidos",
    icon: "ri-home-line",
    target: "",
  },
  {
    id: "20",
    label: "Manutenção",
    link: "/manutencao",
    icon: "ri-tools-line",
    target: "",
  },
  {
    id: "23",
    label: "Solicitação",
    link: "/solicitacao",
    icon: <FaCodePullRequest />,
    target: "",
  },
  {
    id: "11",
    label: "Cera/ Prototipagem",
    link: "/prototipagem",
    icon: <MdOutlineDraw />,
  },
  {
    id: "21",
    label: "Pedidos de Fundição",
    link: "/pedidos-fundicao",
    icon: <AiFillGold />,
    target: "",
  },
  {
    id: "12",
    label: "Fundição",
    link: "/fundicao",
    icon: <GiMeltingMetal />,
    target: "",
  },
  {
    id: "13",
    label: "Acabamento",
    link: "/acabamento",
    icon: <TbHammer />,
    target: "",
  },
  {
    "id": "15",
    "label": "Polimento",
    "icon": <MdOutlineAutoFixHigh />,
    "link": "/polimento",
  },
  {
    id: "14",
    label: "Cravação",
    link: "/cravacao",
    icon: <FaGem />,
    target: "",
  },
  {
    id: "16",
    label: "Expedição",
    link: "/expedicao",
    icon: <FaDolly />,
    target: "",
  },

  // {
  //   "id": "1",
  //   "label": "DashBoards",
  //   "link": "/dashboard",
  //   "icon": "ri-pie-chart-2-line",
  //   "target": ""
  // },
  {
    id: "19",
    label: "Conserto",
    link: "conserto",
    icon: "ri-tools-line",
    target: "",
  },
  {
    id: "4",
    label: "Vitrine",
    link: "/vitrine",
    icon: "ri-suitcase-2-line",
    target: "",
  },
  {
    id: "24",
    label: "Pendências",
    link: '/pendencias',
    icon: <PiWarningCircle />,
    target: "",
  },
  {
    id: "10",
    label: "Resumo Executivo",
    link: "/resumo",
    icon: "ri-pie-chart-2-line",
    target: "",
  },

  {
    id: "7",
    label: "Meu perfil",
    link: "/perfil",
    icon: "ri-account-circle-line",
  },
  {
    id: 2,
    label: "Usuários",
    link: "/usuarios",
    icon: "ri-contacts-book-line",
  },
  {
    id: 2,
    label: "Clientes",
    link: "/clientes",
    icon: <MdPersonOutline />,
  },
  {
    id: 22,
    label: "Extrato",
    link: "/extrato",
    icon: "ri-bank-line",
    target: "",
  },
  {
    id: 25,
    label: 'Faturamento',
    link: "/faturamento",
    icon: 'ri-currency-line',
    target: "",
  },
  {
    id: 26,
    label: 'Gerenciamento',
    link: "/gerenciamento",
    icon: 'ri-briefcase-line',
    target: "",
  },

  {
    label: "Events Management",
    link: "/dashboard/events",
    icon: "ri-calendar-todo-line",
    target: "",
  },

  {
    label: "Website Analytics",
    link: "/dashboard/analytics",
    icon: "ri-bar-chart-2-line",
    target: "",
  },
  {
    label: "Cryptocurrency",
    link: "/dashboard/crypto",
    icon: "ri-coin-line",
    target: "",
  },

  {
    label: "Storage Management",
    link: "/dashboard/storage",
    icon: "ri-hard-drive-2-line",
    target: "",
  },
];

if (utp === "interno") {
  dashboardMenu.push({
    id: "4",
    label: "Produtos",
    link: "/produtos",
    icon: "ri-suitcase-2-line",
    target: "",
  });

  // dashboardMenu.push({
  //   id: "8",
  //   label: "Saldo",
  //   link: "/saldo",
  //   icon: "ri-shopping-bag-3-line",
  //   target: "",
  // });
}

const applicationsMenu = [
  {
    label: "File Manager",
    link: "/apps/file-manager",
    icon: "ri-folder-2-line",
  },
  {
    label: "Email",
    link: "/apps/email",
    icon: "ri-mail-send-line",
  },
  {
    label: "Calendar",
    link: "/apps/calendar",
    icon: "ri-calendar-line",
  },
  {
    label: "Chat",
    link: "/apps/chat",
    icon: "ri-question-answer-line",
  },
  {
    id: "2",
    label: "Contacts",
    link: "/apps/contacts",
    icon: "ri-contacts-book-line",
  },
  {
    label: "Task Manager",
    link: "/apps/tasks",
    icon: "ri-checkbox-multiple-line",
  },
  {
    label: "Media Gallery",
    icon: "ri-gallery-line",
    submenu: [
      {
        label: "Music Stream",
        link: "/apps/gallery-music",
      },
      {
        label: "Video Stream",
        link: "/apps/gallery-video",
      },
    ],
  },
];

const pagesMenu = [
  {
    label: "User Pages",
    icon: "ri-account-circle-line",
    submenu: [
      {
        label: "User Profile",
        link: "/pages/profile",
      },
      {
        label: "People & Groups",
        link: "/pages/people",
      },
      {
        label: "Activity Log",
        link: "/pages/activity",
      },
      {
        label: "Events",
        link: "/pages/events",
      },
      {
        label: "Settings",
        link: "/pages/settings",
      },
    ],
  },
  {
    id: 27,
    label: "Authentication",
    icon: "ri-lock-2-line",
    submenu: [
      {
        label: "Sign In Basic",
        link: "/pages/signin",
      },
      {
        label: "Sign In Cover",
        link: "/pages/signin2",
      },
      {
        label: "Sign Up Basic",
        link: "/pages/signup",
      },
      {
        label: "Sign Up Cover",
        link: "/pages/signup2",
      },
      {
        label: "Verify Account",
        link: "/pages/verify",
      },
      {
        label: "Forgot Password",
        link: "/pages/forgot",
      },
      {
        label: "Lock Screen",
        link: "/pages/lock",
      },
    ],
  },
  {
    label: "Error Pages",
    icon: "ri-error-warning-line",
    submenu: [
      {
        label: "Page Not Found",
        link: "/pages/error-404",
      },
      {
        label: "Internal Server Error",
        link: "/pages/error-500",
      },
      {
        label: "Service Unavailable",
        link: "/pages/error-503",
      },
      {
        label: "Forbidden",
        link: "/pages/error-505",
      },
    ],
  },
  {
    label: "Other Pages",
    icon: "ri-file-text-line",
    submenu: [
      {
        label: "Pricing",
        link: "/pages/pricing",
      },
      {
        label: "FAQ",
        link: "/pages/faq",
      },
    ],
  },
];

const uiElementsMenu = [
  {
    label: "Getting Started",
    icon: "ri-pencil-ruler-2-line",
    submenu: [
      {
        label: "Grid System",
        link: "/docs/layout/grid",
      },
      {
        label: "Columns",
        link: "/docs/layout/columns",
      },
      {
        label: "Gutters",
        link: "/docs/layout/gutters",
      },
    ],
  },
  {
    label: "Components",
    icon: "ri-suitcase-line",
    submenu: [
      {
        label: "Accordion",
        link: "/docs/com/accordions",
      },
      {
        label: "Alerts",
        link: "/docs/com/alerts",
      },
      {
        label: "Avatars",
        link: "/docs/com/avatars",
      },
      {
        label: "Badge",
        link: "/docs/com/badge",
      },
      {
        label: "Breadcrumbs",
        link: "/docs/com/breadcrumbs",
      },
      {
        label: "Buttons",
        link: "/docs/com/buttons",
      },
      {
        label: "Cards",
        link: "/docs/com/cards",
      },
      {
        label: "Carousel",
        link: "/docs/com/carousel",
      },
      {
        label: "Dropdown",
        link: "/docs/com/dropdown",
      },
      {
        label: "Images",
        link: "/docs/com/images",
      },
      {
        label: "List Group",
        link: "/docs/com/listgroup",
      },
      {
        label: "Markers",
        link: "/docs/com/markers",
      },
      {
        label: "Modal",
        link: "/docs/com/modal",
      },
      {
        label: "Nav & Tabs",
        link: "/docs/com/navtabs",
      },
      {
        label: "Offcanvas",
        link: "/docs/com/offcanvas",
      },
      {
        label: "Pagination",
        link: "/docs/com/pagination",
      },
      {
        label: "Placeholders",
        link: "/docs/com/placeholders",
      },
      {
        label: "Popovers",
        link: "/docs/com/popovers",
      },
      {
        label: "Progress",
        link: "/docs/com/progress",
      },
      {
        label: "Spinners",
        link: "/docs/com/spinners",
      },
      {
        label: "Toast",
        link: "/docs/com/toasts",
      },
      {
        label: "Tooltips",
        link: "/docs/com/tooltips",
      },
      {
        label: "Tables",
        link: "/docs/com/tables",
      },
    ],
  },
  {
    label: "Forms",
    icon: "ri-list-check-2",
    submenu: [
      {
        label: "Text Elements",
        link: "/docs/form/elements",
      },
      {
        label: "Selects",
        link: "/docs/form/selects",
      },
      {
        label: "Checks & Radios",
        link: "/docs/form/checksradios",
      },
      {
        label: "Range",
        link: "/docs/form/range",
      },
      {
        label: "Pickers",
        link: "/docs/form/pickers",
      },
      {
        label: "Layouts",
        link: "/docs/form/layouts",
      },
    ],
  },
  {
    label: "Charts & Graphs",
    icon: "ri-bar-chart-2-line",
    submenu: [
      {
        label: "ApexCharts",
        link: "/docs/chart/apex",
      },
      {
        label: "Chartjs",
        link: "/docs/chart/chartjs",
      },
    ],
  },
  {
    label: "Maps & Icons",
    icon: "ri-stack-line",
    submenu: [
      {
        label: "Leaflet Maps",
        link: "/docs/map/leaflet",
      },
      {
        label: "Vector Maps",
        link: "/docs/map/vector",
      },
      {
        label: "Remixicon",
        link: "/docs/icon/remix",
      },
      {
        label: "Feathericons",
        link: "/docs/icon/feather",
      },
    ],
  },
  {
    label: "Utilities",
    icon: "ri-briefcase-4-line",
    submenu: [
      {
        label: "Background",
        link: "/docs/util/background",
      },
      {
        label: "Border",
        link: "/docs/util/border",
      },
      {
        label: "Colors",
        link: "/docs/util/colors",
      },
      {
        label: "Divider",
        link: "/docs/util/divider",
      },
      {
        label: "Flex",
        link: "/docs/util/flex",
      },
      {
        label: "Sizing",
        link: "/docs/util/sizing",
      },
      {
        label: "Spacing",
        link: "/docs/util/spacing",
      },
      {
        label: "Opacity",
        link: "/docs/util/opacity",
      },
      {
        label: "Position",
        link: "/docs/util/position",
      },
      {
        label: "Typography",
        link: "/docs/util/typography",
      },
      {
        label: "Shadows",
        link: "/docs/util/shadows",
      },
      {
        label: "Extras",
        link: "/docs/util/extras",
      },
    ],
  },
];

export { dashboardMenu, applicationsMenu, pagesMenu, uiElementsMenu };
