import React, { useContext, useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Badge, Button, Card, Col, Form, Modal, Nav, ProgressBar, Row, Tab, Table } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Avatar from "../../components/Avatar";
import { Link } from "react-router-dom";

import img8 from "../../assets/img/img8.jpg";
import img9 from "../../assets/img/img9.jpg";
import img10 from "../../assets/img/img10.jpg";
import img11 from "../../assets/img/img11.jpg";
import img12 from "../../assets/img/img12.jpg";
import img13 from "../../assets/img/img13.jpg";
import img15 from "../../assets/img/img15.jpg";
import img18 from "../../assets/img/img18.jpg";
import img19 from "../../assets/img/img19.jpg";
import { AccountContext } from "../../Context/AccountContext/AccountContext";
import { toastErro, toastSucesso } from "../../components/Swal";

export const GerencialPage = () => {
	const { accountBalancePedding, list, concluiAccountPendding } = useContext(AccountContext);
	const handleFetchAccountBalance = async () => {
		await accountBalancePedding();
	};
	useEffect(() => {
		handleFetchAccountBalance();
		document.body.classList.add("app-task");
		return () => {
			document.body.classList.remove("app-task");
		};
	}, []);

	const [startDate, setStartDate] = useState(new Date());
	const [selectedTab, setSelectedTab] = useState(1);
	const [tabs, setTabs] = useState([
		{ id: 1, label: "Pendentes" },
		{ id: 2, label: "Concluídos" },
	]);
	const [selectedBalance, setSelectedBalance] = useState({});
	const [openBalance, setOpenBalance] = useState(false);
	const handleOpenConclusao = balance => {
		setOpenBalance(true);
		setSelectedBalance(balance);
	};

	const handleCloseConclusao = () => {
		setOpenBalance(false);
	};

	const [payload, setPayload] = useState({
		balance_id: selectedBalance.id_balance,
		observacoes: "",
	});

	useEffect(() => {
		setPayload({ ...payload, balance_id: selectedBalance.id_balance });
	}, [selectedBalance]);

	const handleConclusao = async () => {
		const response = await concluiAccountPendding(payload);
		console.log(response);
		if (response.data.status == "success") {
			toastSucesso("Balanço concluído com sucesso!");
			handleFetchAccountBalance();
			handleCloseConclusao();
		} else {
			toastErro("Erro ao concluir balanço!");
		}
	};

	return (
		<>
			<div className="main main-app p-3 p-lg-4 d-flex flex-column gap-3">
				<Card className="card-one">
					<Card.Body>
						<div className="d-md-flex align-items-center justify-content-between">
							<div>
								<Card.Title as={"h4"}>Gerenciamento</Card.Title>
							</div>
						</div>
					</Card.Body>
				</Card>
				<Card className="card-one">
					<Card.Header className="d-flex flex-row gap-3">
						{tabs.map(tab => (
							<Button onClick={() => setSelectedTab(tab.id)} variant={selectedTab == tab.id ? "primary" : "outline-primary"} clas key={tab.id}>
								{tab.label}
							</Button>
						))}
					</Card.Header>
					<Card.Body>
						{selectedTab == 1 && (
							<Table bordered hover>
								<thead>
									<tr>
										<th>Ourives</th>
										<th>Saldo Negativo</th>
										<th>Data de Fechamento do Balanço</th>
										<th>Período de Fechamento</th>
										<th>Status</th>
										<th>Ações</th>
									</tr>
								</thead>
								<tbody>
									{list?.map((item, index) =>
										item.concluido == "0" ? (
											<tr key={index}>
												<td>{item.name}</td>
												<td>{item.prev_balance}</td>
												<td>{new Date(item.created_at).toLocaleString()}</td>
												<td>
													<div className="d-flex flex-column">
														<span>{!isNaN(new Date(item.periodo_ate).getTime()) ? new Date(item.periodo_ate).toLocaleString() : null}</span>
														<span>{!isNaN(new Date(item.periodo_de).getTime()) ? new Date(item.periodo_de).toLocaleString() : null}</span>
													</div>
												</td>
												<td>{item.concluido == "1" ? "Concluído" : "Pendente"}</td>
												<td>
													<Button onClick={e => handleOpenConclusao(item)}>Concluir</Button>
												</td>
											</tr>
										) : null
									)}
								</tbody>
							</Table>
						)}

						{selectedTab == 2 && (
							<Table bordered hover>
								<thead>
									<tr>
										<th>Ourives</th>
										<th>Saldo Negativo</th>
										<th>Data de Fechamento do Balanço</th>
										<th>Período de Fechamento</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									{list?.map((item, index) =>
										item.concluido == "1" ? (
											<tr key={index}>
												<td>{item.name}</td>
												<td>{item.prev_balance}</td>
												<td>{new Date(item.created_at).toLocaleString()}</td>
												<td>
													<div className="d-flex flex-column">
														<span>{!isNaN(new Date(item.periodo_ate).getTime()) ? new Date(item.periodo_ate).toLocaleString() : null}</span>
														<span>{!isNaN(new Date(item.periodo_de).getTime()) ? new Date(item.periodo_de).toLocaleString() : null}</span>
													</div>
												</td>
												<td>{item.concluido == "1" ? "Concluído" : "Pendente"}</td>
											</tr>
										) : null
									)}
								</tbody>
							</Table>
						)}
					</Card.Body>
				</Card>
			</div>
			<Modal show={openBalance} onHide={handleCloseConclusao}>
				<Modal.Header closeButton>
					<Modal.Title>Conclusão de Balanço</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Deseja realmente concluir o balanço de {selectedBalance.name}?</p>
					<Form.Control
						rows={5}
						value={payload.observacoes}
						className="mb-3"
						as="textarea"
						onChange={e => setPayload({ ...payload, observacoes: e.target.value })}
						placeholder="Observações"
					></Form.Control>
					<div className="d-flex justify-content-between gap-2">
						<Button variant="secondary" onClick={handleCloseConclusao}>
							Cancelar
						</Button>
						<Button variant="primary" onClick={handleConclusao}>
							Concluir
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};
